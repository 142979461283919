<template>
  <div class="container">
    <form>
      <h1 class="text-blue text-center">Create Profile</h1>
      <small v-if="error" class="text-danger">{{ error }}</small>
      <div v-if="image">
        <img :src="image" class="profile-img" />
        <br /><br />
      </div>
      <div class="form-group">
        <input
          type="text"
          class="form-control"
          placeholder="Full Name"
          v-model="name"
        />
        <small v-if="nameError" class="text-danger">{{ nameError }}</small>
      </div>
      <br />
      <div class="form-group">
        <select
          class="form-control"
          id="exampleFormControlSelect1"
          v-model="position"
        >
          <option selected>Position...</option>
          <option
            v-for="(position, index) in positions"
            :key="index"
            :value="position"
          >
            {{ position }}
          </option>
        </select>
        <small v-if="positionError" class="text-danger">{{
          positionError
        }}</small>
      </div>
      <br />
      <h5 class="text-blue">Upload Profile Photo</h5>
      <input
        ref="fileInput"
        type="file"
        accept=".png, .jpg, .jpeg"
        @input="pickFile"
      />
      <br />
      <small v-if="imageError" class="text-danger">{{ imageError }}</small>
      <br /><br />
      <div class="text-center">
        <button
          type="submit"
          class="btn btn-profile"
          :class="disabled ? 'disabled' : ''"
          @click.prevent="createProfile"
        >
          Create Profile
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { v4 as uuid4 } from "uuid";
import { mapGetters } from "vuex";

import { uploadImage } from "../../firebase";

export default {
  name: "CreateProfile",
  data() {
    return {
      positions: ["Lawyer", "Software Engineer"],
      error: "",
      image: null,
      imageExtension: "",
      imageError: "",
      name: "",
      nameError: "",
      position: "Position...",
      positionError: "",
      disabled: false,
    };
  },
  methods: {
    ...mapGetters(["getUser"]),
    async createProfile() {
      this.imageError = "";
      this.nameError = "";
      this.positionError = "";
      this.disabled = true;

      let errors = 0;

      if (!this.name.length || !this.name.replace(/\s/g, "").length) {
        errors++;
        this.nameError = "Name can not be empty.";
      }

      if (!this.positions.includes(this.position)) {
        errors++;
        this.positionError = "Please select a position in the company.";
      }

      if (!this.image) {
        errors++;
        this.imageError = "Please add a profile image.";
      }

      if (errors > 0) {
        this.disabled = false;
        return;
      }

      // * Upload image
      const imagePath = `profile/${uuid4()}.${this.imageExtension}`;
      const res = await uploadImage(this.image, imagePath);
      if (!res.isValid) {
        this.disabled = false;
        this.error = "An error has occured";
        return;
      }

      const user = {
        uid: this.getUser().uid,
        profile: {
          name: this.name,
          position: this.position,
          image: res.imageURL,
          roles: ["user"],
        },
      };

      const resp = await this.$store.dispatch("createProfile", user);
      if (!resp.isValid) {
        this.error = "An error has occured";
        this.disabled = false;
        return;
      }

      this.image = null;
      this.imageError = "";
      this.name = "";
      this.nameError = "";
      this.position = "Position...";
      this.positionError = "";
      this.disabled = false;

      this.$router.push("/");
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;

      if (file && file[0]) {
        this.imageExtension = file[0].name.slice(
          ((file[0].name.lastIndexOf(".") - 1) >>> 0) + 2
        );

        let reader = new FileReader();
        reader.onload = (e) => {
          this.image = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
      }
    },
  },
};
</script>

<style scoped>
.profile-img {
  border: none;
  border-radius: 50%;
  height: 150px;
  width: 150px;
}

.btn-profile {
  background-color: #023b95;
  color: white;
  border: none;
  border-radius: 30px;
  width: 60%;
  padding: 10px;
}
</style>
